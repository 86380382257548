import { buildParams } from '@/utils';

const version = '/v1';
const version2 = '/v2';

const BANNER_LIST = `${version}/marketing/banner/list`;

const FILTER_PRODUCT = `${version}/product/util/type`;
const FILTER_SORT = `${version}/product/util/sort`;
const FILTER_UTIL = `${version}/product/util/filter`;
const PRODUCT_LIST_RECOMMENDED = `${version}/product/recommended`;
const PRODUCT_LIST_TRENDING = `${version}/product/trending`;
const PRODUCT_LIST_TERLARIS = `${version}/product/best_seller`;
const PRODUCT_LIST_TERBARU = `${version}/product/newest`;
const PORTFOLIO = `${version}/product/category/portfolio`;
const PRODUCT_LIST_PORTFOLIO = (categoryID: number) => `${version}/product/category/portfolio/${categoryID}`;
const PRODUCT_LIST_FAVORITE = `${version}/product/list`;
const PRODUCT_DETAIL = (productID: number, type: string) =>
  `${version}/product/${productID}/${type === '' ? 'marketplace' : 'bundle'}`;
const PRODUCT_DETAIL_BUNDLE = (productID: number) => `${version}/product/${productID}/bundle`;
const PRODUCT_SEARCH_AUTOCOMPLETE = (query: string) => `${version}/product/search/suggestion?q=${query}`;
const PRODUCT_SEARCH = (request: { query: string | undefined; page: number; keyword: string }) =>
  `${version}/product/search${buildParams(request)}`;
const PRODUCT_ADD_TO_FAVORITE = `${version}/product/dropship`;
const PRODUCT_DELETE_FROM_FAVORITE = (productID: number) => `${version}/product/dropship/${productID}`;
const UPLOAD_IMAGE = `${version}/product/image`;

const COURIER_FEE_ALL = `${version}/shipping/courier/courier_fee_all`;

const CART_COUNT = `${version}/order/cart/count`;
const CART = `${version}/order/cart`;
const ORDER_STATUS_LIST = `${version}/order/status_order/list`;
const ORDER_LIST = `${version}/order`;
const PRE_CHECKOUT = `${version}/order/cart/pre-checkout`;
const SHIPPING_ESTIMATION = `${version}/shipping/pricings/estimate/all-services`;
const CHECKOUT = `${version}/order/cart/checkout`;
const CART_DELETE = (itemID: string) => `${version}/order/cart/${itemID}`;
const VOUCHER_AVAILABLE = `${version}/voucher/check`;
const VOUCHER_DETAIL = (voucherID: string) => `${version}/voucher/${voucherID}`;
const ORDER_DETAIL = (orderID: string) => `${version}/order/${orderID}`;
const TRANSACTION_DETAIL = (transactionID: string) => `${version}/order/transaction/${transactionID}`;
const POTENTIAL_REWARD = `${version}/rewards/potential`;
const ORDER_FINANCE = (status: number, page: number) => {
  if (status === 81) {
    return `${version}/order/finance/list?penalty=true&limit=10&page=${page}&sortBy=created_at`;
  } else {
    return `${version}/order/finance/list?status=${status}&limit=10&page=${page}&sortBy=created_at`;
  }
};
const ORDER_FINANCE_HISTORY = `${version}/payment/withdraw/history`;
const BANK_LIST = `${version}/payment/bank/list`;
const REKENING_LIST = `${version}/reseller/rekening/list`;
const REKENING_ADD = `${version}/reseller/rekening/add`;
const REKENING_EDIT = `${version}/reseller/rekening/`;
const WITHDRAW = `${version}/payment/withdraw/request`;
const WITHDRAW_CHECK_OTP = `${version}/payment/withdraw/check_otp`;
const WITHDRAW_VALIDATE_PIN = `${version2}/payment/withdraw/validate-pin`;
const REWARD_HISTORY = `${version}/rewards/history`;
const REWARD_DETAIL = (rewardID: number) => `${version}/rewards/${rewardID}`;
const REWARD_CLAIM = `${version}/rewards/claim`;
const HOLD_SALDO = `${version}/reseller/hold-balances/amount`;
const HOLD_SALDO_LIST = (status: string, page: number) =>
  `${version}/reseller/hold-balances/list?statusName=${status}&page=${page}&limit=20`;

const COMPLAINT_CATEGORIES = `${version}/complaint/complaint-enum/categories`;
const COMPLAINT_EXPECTATIONS = `${version}/complaint/complaint-enum/expectations`;
const COMPLAINT_NEW = `${version}/complaint/complaint/store`;
const COMPLAINT_ATTACHMENT = `${version}/complaint/complaint/upload`;
const COMPLAINT_DETAIL = (complaintID: string) => `${version}/complaint/complaint/${complaintID}`;

const ADDRESS_LIST = (query: string) => `${version}/shipping/customer_address/list?search=${query}`;
const ADDRESS_ADD = `${version}/shipping/customer_address/create`;
const ADDRESS_EDIT = (addressID: number) => `${version}/shipping/customer_address/${addressID}/update`;
const ADDRESS_DELETE = (addressID: number) => `${version}/shipping/customer_address/${addressID}/delete`;
const ADDRESS_CHECK_WA = `${version}/shipping/customer_address/whatsapp-numbers`;

const RESELLER_ADDRESS = `${version}/reseller/address`;
const RESELLER_PROFILE = `${version}/reseller/auth/my-data`;
const RESELLER_STORE = `${version}/reseller/toko/my-toko`;
const RESELLER_COMMISION = `${version2}/payment/commission/financial`;
const RESELLER_REWARD_SUMMARY = `${version}/rewards/summary`;
const REWARD = `${version}/rewards`;
const RESELLER_PIN = `${version}/reseller/auth/pin/update`;
const RESELLER_REFERRAL = (referralCode: string) => `${version}/referral/detail?code=${referralCode}`;
const KYC_STATUS = `${version}/reseller/kyc/status`;
const KYC = `${version}/reseller/kyc/request`;

// Location
const LOCATION_PROVINCE = `${version}/shipping/province/list`;
const LOCATION_CITY = (provinceID: number) => `${version}/shipping/city/list?province_id=${provinceID}`;
const LOCATION_DISTRICT = (cityID: number) => `${version}/shipping/district/list?city_id=${cityID}`;
const LOCATION_POSTAL_CODE = (districtID: number) => `${version}/shipping/village/list?district_id=${districtID}`;

// Supplier
const SUPPLIER = (supplierID: number) => `${version}/product/category/supplier/${supplierID}/detail`;
const SUPPLIER_SEARCH = (query: string) => `${version}/product/search/supplier?q=${query}&page=1&limit=10`;

// Recommendation Store
const LIST_RECOMMENDATION_STORE = () => `${version}/reseller/toko/recomendation`;
const DETAIL_RECOMMENDATION_STORE = (id: string) => `${version}/reseller/toko/recomendation/${id}`;
const LIST_BUSINESS_TYPE = () => `${version}/reseller/toko/business-type`;
const SEND_RECOMMENDATION_STORE = () => `${version}/reseller/toko/recomendation`;
const SEND_UPGRADE_TIERING = () => `${version}/order/tiering-upgrade`;
const NEXT_TIER = (current_tier: string) => `${version}/order/tiering-upgrade/next-tier/${current_tier}`;
const LIST_DOMICILE = ({ limit, page, name }: { limit: number; page: number; name: string }) =>
  `${version}/shipping/city/list-tiering?limit=${limit}&page=${page}&name=${name}`;
const SEND_PRE_CHECKOUT = () => `${version}/order/cart/pre-checkout`;
const GET_RANKING = () => `${version}/reseller/rank/list`;
const GET_RANKING_MISSION = `${version}/reseller/rank/mission`;
const GET_TIER_SUBMISSION = `${version}/order/tiering-upgrade/my-submission`;
const GET_TIER_DETAIL = (id: string) => `${version}/order/tiering/${id}`;

const Api = {
  BANNER_LIST,

  FILTER_PRODUCT,
  FILTER_SORT,
  FILTER_UTIL,
  PRODUCT_LIST_RECOMMENDED,
  PRODUCT_LIST_TRENDING,
  PRODUCT_LIST_TERLARIS,
  PRODUCT_LIST_TERBARU,
  PORTFOLIO,
  PRODUCT_LIST_PORTFOLIO,
  PRODUCT_LIST_FAVORITE,
  PRODUCT_DETAIL,
  PRODUCT_DETAIL_BUNDLE,
  PRODUCT_SEARCH_AUTOCOMPLETE,
  PRODUCT_SEARCH,
  PRODUCT_ADD_TO_FAVORITE,
  PRODUCT_DELETE_FROM_FAVORITE,
  UPLOAD_IMAGE,

  CART_COUNT,
  CART,
  ORDER_STATUS_LIST,
  ORDER_LIST,
  PRE_CHECKOUT,
  CHECKOUT,
  CART_DELETE,
  VOUCHER_AVAILABLE,
  VOUCHER_DETAIL,
  ORDER_DETAIL,
  TRANSACTION_DETAIL,
  POTENTIAL_REWARD,
  ORDER_FINANCE,
  ORDER_FINANCE_HISTORY,
  BANK_LIST,
  REKENING_LIST,
  REKENING_ADD,
  REKENING_EDIT,
  WITHDRAW,
  WITHDRAW_CHECK_OTP,
  WITHDRAW_VALIDATE_PIN,
  REWARD_HISTORY,
  REWARD_DETAIL,
  REWARD_CLAIM,

  COMPLAINT_CATEGORIES,
  COMPLAINT_EXPECTATIONS,
  COMPLAINT_NEW,
  COMPLAINT_ATTACHMENT,
  COMPLAINT_DETAIL,

  ADDRESS_LIST,
  ADDRESS_ADD,
  ADDRESS_EDIT,
  ADDRESS_DELETE,
  ADDRESS_CHECK_WA,

  LOCATION_PROVINCE,
  LOCATION_CITY,
  LOCATION_DISTRICT,
  LOCATION_POSTAL_CODE,

  RESELLER_ADDRESS,
  RESELLER_PROFILE,
  RESELLER_STORE,
  RESELLER_COMMISION,
  RESELLER_REWARD_SUMMARY,
  REWARD,
  RESELLER_REFERRAL,
  RESELLER_PIN,
  KYC_STATUS,
  KYC,
  HOLD_SALDO,
  HOLD_SALDO_LIST,

  SUPPLIER,
  SUPPLIER_SEARCH,

  COURIER_FEE_ALL,
  SHIPPING_ESTIMATION,

  LIST_RECOMMENDATION_STORE,
  DETAIL_RECOMMENDATION_STORE,
  LIST_BUSINESS_TYPE,
  SEND_RECOMMENDATION_STORE,
  SEND_UPGRADE_TIERING,
  NEXT_TIER,
  LIST_DOMICILE,
  SEND_PRE_CHECKOUT,
  GET_RANKING,
  GET_RANKING_MISSION,
  GET_TIER_SUBMISSION,
  GET_TIER_DETAIL,
};

export default Api;
